export default function Linkedin() {
  return (
    <svg className="hover:scale-105" width="32px" height="32px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#0072b1">
        <title>Linkedin</title>
        <g id="Layer_2">
            <g id="invisible_box">
            <rect width="48" height="48" fill="none"/>
            <rect width="48" height="48" fill="none"/>
            </g>
            <g id="icons_Q2">
                <path d="M41,4.1H7A2.9,2.9,0,0,0,4,7V41.1A2.9,2.9,0,0,0,7,44H41a2.9,2.9,0,0,0,2.9-2.9V7A2.9,2.9,0,0,0,41,4.1Zm-25.1,34h-6v-19h6Zm-3-21.6A3.5,3.5,0,0,1,9.5,13a3.4,3.4,0,0,1,6.8,0A3.5,3.5,0,0,1,12.9,16.5ZM38,38.1H32.1V28.8c0-2.2,0-5-3.1-5s-3.5,2.4-3.5,4.9v9.4H19.6v-19h5.6v2.6h.1a6.2,6.2,0,0,1,5.6-3.1c6,0,7.1,3.9,7.1,9.1Z"/>
            </g>
        </g>
    </svg>
  )
}